// @flow
import * as React from 'react'
import cn from 'classnames'

import { useTranslation } from 'react-i18next'
import { TextBlock } from '@shift-marketing/shift-one-website-editor'

import { createClickActivity } from 'Actions'

import withTheme from 'hoc/withTheme'

import transDomain from './translations/index.translations'

import * as Form from 'components/Form'

import withForm, { WithFormProps } from 'components/Form/Helpers/FormHOC'
import supportedThemes from './themes/__supportedThemes'

import styles from './styles.module.scss'

type Props = WithFormProps | {
    values: {
        comments: ?String
    }
}

function LeadGenForm (props: Props) {
    const { handleFieldChange, values, isLoading } = props
    const { t } = useTranslation(transDomain)

    const handleComments = React.useCallback(value => {
        return handleFieldChange('comments', value)
    }, [handleFieldChange])

    const phoneField = React.useMemo(() => {
        if (props.values.mobilePhone) {
            return 'mobilePhone'
        }

        if (props.values.homePhone) {
            return 'homePhone'
        }

        for (let field in props.values) {
            if (field.endsWith('Phone') && props.values[field]) {
                return field
            }
        }

        return 'mobilePhone'
    }, [props.values])


    return (
        <div
            className={cn(styles.form)}
        >
            <Form.Row>
                <Form.RowColumns columnsTablet={1}>
                    <div className={styles.title}>
                        <TextBlock
                            blockId={'leadGenForm.personalInformation.label'}
                            defaultContent={`<p>${t('title.personalInformation')}</p>`}
                            inline
                            onAnchorClick={createClickActivity}
                        />
                    </div>

                    <Form.RowColumn size={1} className={styles.row1}>
                        <Form.Field
                            defaultValue={values.firstName || null}
                            required={true}
                            onChange={value => {
                                handleFieldChange('firstName', `${value}`)
                            }}
                            type="text"
                            placeholder={t('fields.name')}
                        />

                        <Form.Field
                            defaultValue={values.lastName || null}
                            required={true}
                            onChange={value => {
                                handleFieldChange('lastName', `${value}`)
                            }}
                            type="text"
                            placeholder={t('fields.lastname')}
                        />
                    </Form.RowColumn>

                    <Form.RowColumn size={1} className={styles.row2}>
                        <Form.Field
                            defaultValue={values.addressLine1 || null}
                            required={true}
                            onChange={value => {
                                handleFieldChange('addressLine1', `${value}`)
                            }}
                            type="text"
                            placeholder={t('fields.address')}
                        />

                        <Form.Field
                            defaultValue={values.addressLine2 || null}
                            onChange={value => { handleFieldChange('addressLine2', `${value}`) }}
                            type="number"
                            required={false}
                            placeholder={t('fields.unit')}
                        />
                    </Form.RowColumn>

                    <Form.RowColumn size={1} className={styles.row3}>
                        <Form.Field
                            defaultValue={values.city || null}
                            required={true}
                            onChange={value => {
                                handleFieldChange('city', `${value}`)
                            }}
                            type="text"
                            placeholder={t('fields.city')}
                        />

                        <Form.Field
                            defaultValue={values.province || null}
                            required={true}
                            onChange={value => {
                                handleFieldChange('province', `${value}`)
                            }}
                            type="text"
                            placeholder={t('fields.state')}
                        />

                        <Form.Field
                            defaultValue={values.postalCode || null}
                            required={true}
                            onChange={value => {
                                handleFieldChange('postalCode', `${value}`)
                            }}
                            type="number"
                            placeholder={t('fields.zipcode')}
                        />
                    </Form.RowColumn>

                    <Form.RowColumn size={1} className={styles.row4}>
                        <Form.Field
                            defaultValue={values[phoneField] || null}
                            required={true}
                            onChange={value => {
                                handleFieldChange(phoneField, `${value}`)
                            }}
                            type="phone"
                            placeholder={t('fields.phone')}
                        />

                        <Form.Field
                            defaultValue={values.email || null}
                            required={true}
                            onChange={value => {
                                handleFieldChange('email', `${value}`)
                            }}
                            type="email"
                            placeholder={t('fields.email')}
                        />
                    </Form.RowColumn>

                    <div className={styles.title}>
                        <TextBlock
                            blockId={'leadGenForm.fields.comments.label'}
                            defaultContent={`<p>${t('fields.comments.label')}</p>`}
                            inline
                            onAnchorClick={createClickActivity}
                        />
                    </div>

                    <Form.RowColumn size={1}>
                        <Form.Textarea
                            defaultValue={values.comments || null}
                            onChange={handleComments}
                            type="textarea"
                            required={false}
                            rows={9}
                            placeholder={t('fields.comments.placeholder')}
                        />
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>

            <div className={styles.separator}/>

            <Form.Row>
                <Form.RowColumns className={styles.submitContainer}>
                    <Form.SubmitBlock
                        loading={isLoading}
                        blockId={'leadGenForm.submit'}
                        label={t('submit.label')}
                    />
                </Form.RowColumns>
            </Form.Row>
        </div>
    )
}

LeadGenForm.defaultProps = {
    values: {}
}

export default withForm(true)(withTheme(supportedThemes)(LeadGenForm))
